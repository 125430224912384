<template>
  <Card>
    <template #content>
      <LoadingSpinner :loading="loading" />
      <!-- BEGIN DATATABLE -->
        <DataTable
          v-if="!loading"
          v-model:first="first"
          class="p-datatable-sm"
          :paginator="true"
          :rows="rows"
          :value="invoices"
          @filter="handleFilter"
          filterDisplay="menu"
          v-model:filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, 50]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          @page="handlePage"
          @sort="sortData($event.sortField)"
          :sortField="'invoice_date'"
          :sortOrder="-1"
        >
          <template #empty> No Invoices's to display. </template>
          <Column field="ar_id" header="Id" :showFilterMatchModes="false" :showFilterOperator="false" sortable>
            <template #body="{ data }">
              {{ data.ar_id }}
            </template>
            <template #filter>
              <InputText
                type="text"
                v-model="selectedFilterId"
                class="p-column-filter"
                placeholder="Search by Id"
              />
            </template>
            <template #filterclear>
              <Button
                type="button"
                label="Clear"
                @click="this.selectedFilterId = ''; fetchInvoices(false)"
              />
            </template>
            <template #filterapply>
              <Button
                type="button"
                label="Apply"
                @click="
                fetchInvoices(false)
                "
              />
            </template>
          </Column>
          <Column field="invoice_date" header="AR Date" sortable> 
          <template #body="{data}">
            {{this.formatDate(data.invoice_date)}}
          </template>
          </Column>
          <Column field="due_date" header="Due Date" :showFilterMatchModes="true" :showFilterOperator="false" sortable>
            <template #body="{data}">
              {{this.formatDate(data.due_date)}}
            </template>
            <template #filter>
              <Calendar v-model="selectedDateRange" dateFormat="mm-dd-yy" class="p-column-filter" selectionMode="range" placeholder="Select Date Range" />
            </template>
            <template #filterclear>
              <Button type="button" label="Clear" @click="this.selectedDateRange = ''; fetchInvoices(false)" />
            </template>
            <template #filterapply>
              <Button type="button" label="Apply" @click="fetchInvoices(false)" />
            </template>
          </Column>
          <Column field="type" header="Type" :showFilterMatchModes="false" :showFilterOperator="false" sortable>
            <template #body="{data}">
              <div v-for="types in Types" :key="types.code">
                <div v-if="types.code === data.type">
                  {{ types.name }}
                </div>
              </div>
            </template>
            <template #filter>
                <MultiselectWithButton
                  :value="selectedFilterTypes"
                  :options="Types"
                  optionLabel="name"
                  optionValue="code"
                  placeHolder="Filter By Type"
                  :style="{ width: '150px' }"
                  btnLabel="Search"
                  @btnCallback="fetchInvoices(false)"
                  @updateFilterValue="(filterValue) => this.selectedFilterTypes = filterValue"
                />
              </template>
              <template #filterclear>
                <Button
                  type="button"
                  label="Clear"
                  @click="this.selectedFilterTypes = []; this.filters.type.value = []; fetchInvoices(false)"
                />
              </template>
              <template #filterapply>
                <Button
                  type="button"
                  label="Search"
                  @click="$event => fetchInvoices(false)"
                />
              </template>
          </Column>
          <Column field="invoice_amt" header="AR Amount" sortable bodyStyle="text-align: right">
          <template #body="{data}">
            {{formatCurrency(data.invoice_amt)}}
          </template>
          </Column>
          <Column field="balance" header="Balance" sortable bodyStyle="text-align: right">
          <template #body="{data}">
            {{formatCurrency(data.balance)}}
          </template>
          </Column>

          <Column field="status" header="Status" :showFilterMatchModes="false" :showFilterOperator="false">
              <template #body="data">
                {{ displayStatus(data.data) }}
              </template>
              <template #filter>
                <MultiselectWithButton 
                  :value="selectedFilterStatuses"
                  :options="status"
                  optionLabel="name"
                  optionValue="code"
                  placeHolder="Filter By Status"
                  :style="{ width: '150px' }"
                  btnLabel="Search"
                  @btnCallback="fetchInvoices(false)"
                  @updateFilterValue="(filterValue) => this.selectedFilterStatuses = filterValue"
                />
              </template>
              <template #filterclear>
                <Button
                  type="button"
                  label="Clear"
                  @click="$event => clearStatus()"
                />
              </template>
              <template #filterapply>
                <Button
                  type="button"
                  label="Search"
                  @click="$event => fetchInvoices(false)"
                />
              </template>
            </Column>
          <Column header="Actions">
            <template #body="data">
              <span>
              <Button
                :icon="invoiceIcon(data.data.ar_id)"
                :title="'Generate Invoice ' + data.data.ar_id"
                @click="fetchPDFInvoice(data.data.ar_id)"
              >
              </Button>
              &nbsp;
              <Button
                id="emailButton"
                icon="pi pi-envelope"
                title="Email"
                @click="showEmailModal(data.data)"
              />
              </span>
              &nbsp;
              <span v-if="data.data.status === 'O' && data.data.ach_pending !== 'Y'">
                <span v-if="existInPayArray(data.data)">
                  <Button
                    icon="pi pi-check"
                    class="p-button-success"
                    :title="'Selected'"
                    @mouseover="hoverPayBottonIndex = data.index"
                    v-show="hoverPayBottonIndex !== data.index"
                  />
                  <Button
                    icon="pi pi-minus"
                    class="p-button-danger"
                    :title="'Remove'"
                    @mouseleave="hoverPayBottonIndex = -1"
                    v-if="hoverPayBottonIndex === data.index"
                    @click="removeInvoiceToPay({
                      arId: data.data.ar_id,
                      balance: parseFloat(data.data.balance),
                    })"
                  />
                </span>
                <span v-else>
                  <Button
                    icon="pi pi-plus"
                    :title="'Click to Pay'"
                    @click="addInvoiceToPay(data.data)"
                  />
                </span>
              </span>
            </template>
          </Column>
        </DataTable>
      <!-- END DATATABLE -->
      <div
        class="grid mt-2 mr-0 w-10 justify-content-center col-offset-1"
        v-if="invoice.invoicesToPay.length > 0"
      >
        <div class="sm:col-12 md:col-8">
          <PaymentCard :status="selectedStatus" @payment-submitted="handlePaymentSubmitted" :index="index" />
        </div>
      </div>
    </template>
  </Card>
  <Footer />
  <EmailFileDialog
    :show="showEmailInvoiceDialog"
    :header="'Email Invoice ' + currentInvoiceId"
    @hide="(isHidden) => { showEmailInvoiceDialog = isHidden; }"
    :fileId="currentInvoiceId"
    :fileName="currentInvoiceId"
    @onEmail="sendEmail"
    :contacts="getCustomer(index).contact_id_items"
  />
</template>

<script>
import Card from "primevue/card";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Footer from "@/components/Footer.vue";
import Button from "primevue/button";
import Tooltip from "primevue/tooltip";
import PaymentCard from "@/components/PaymentCard.vue";
import Calendar from "primevue/calendar"
import InputText from "primevue/inputtext"
import MultiselectWithButton from "@/components/UI/MultiselectWithButton.vue";
import EmailFileDialog from "@/components/UI/EmailFileDialog.vue";
import Utils from "@/utility/utils";
import { FilterMatchMode, FilterOperator } from "primevue/api";

import { mapActions, mapGetters, mapState } from "vuex";

import InvoiceService from "@/services/InvoiceService";

export default {
  name: "Invoices",
  props: {
    index: Number,
  },
  components: {
    InputText,
    Calendar,
    DataTable,
    Column,
    LoadingSpinner,
    Footer,
    Button,
    Card,
    PaymentCard,
    MultiselectWithButton,
    EmailFileDialog
  },
  directives: {
    tooltip: Tooltip,
  },
  created() {
    if (this.getInvoices(this.index) == null) {
      this.fetchInvoices(false);
    } else {
      this.invoices = this.getInvoices(this.index);
      this.invoices.forEach(invoice => {invoice.due_date = new Date(invoice.due_date); invoice.invoice_date = new Date(invoice.invoice_date);})
    }
    if ((this.getInvoices(this.index)?.length || 0) >= this.rows) {
      this.first = this.getPageIndex(this.index) * this.rows;
    }
    if(this.invoice.invoicesToPay[0]?.cust !== this.getCustomer(this.index).cust_id) {
        this.clearInvoicesToPay()
    }
  },
  computed: {
    ...mapState(["invoice"]),
    ...mapGetters({
      getInvoices: "customerInquiry/getInvoices",
      getCustomer: "customerInquiry/getCustomer",
      getRangeEnd: "customerInquiry/getRangeEnd",
      getPageIndex: "customerInquiry/getPageIndex",
      getClient: "session/getClient",
      getArIds: "customerInquiry/getArIds"
    }),
  },
  data() {
    return {
      Types: [
        { name: "Credit Memo", code: "CM" },
        { name: "Debit Memo", code: "DM" },
        { name: "Invoice", code: "IN" },
        { name: "On Account", code: "OA" },
      ],
      loading: false,
      selectedFilterStatuses: [],
      selectedFilterTypes: [],
      selectedFilterDate: "",
      selectedFilterId: "",
      sortValue: "",
      selectedDateRange: "",
      first: 0,
      rows: 5,
      page: 1,
      sortOrder: 1,
      sortField: "",
      sortOrderString: "",
      invoices: [],
      status: [
        { name: "Outstanding", code: "O" },
        { name: "Paid", code: "P" },
        { name: "Payment Pending", code: "Y" },
      ],
      invoicesView: [],
      loadingPDFInvoices: [],
      invoiceService: new InvoiceService(process.env.VUE_APP_ABSTRACTION_API),
      filters: {
        status: { value: [], matchMode: FilterMatchMode.IN,},
        type: { value: [], matchMode: FilterMatchMode.IN,},
        ar_id: {value: "", matchMode: FilterMatchMode.EQUALS,},
        due_date: {value:  [], matchMode: FilterMatchMode.IN,}
      },
      hoverPayBottonIndex: -1,
      showEmailInvoiceDialog: false,
      currentInvoiceId: '',
    };
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
      fetchCustomerInvoices: "customerInquiry/fetchInvoices",
      updatePage: "customerInquiry/updatePage",
      postInvoiceToPay: "invoice/addInvoiceToPay",
      removeInvoiceToPay: "invoice/removeInvoiceToPay",
      clearInvoicesToPay: "invoice/clearInvoicesToPay"
    }),
    formatDate(date) {
      return Utils.formatDate(date);
    },
    formatCurrency(amount){
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(parseFloat(amount))
    },
    handleFilter(event) {
      this.$emit('update-badge', 'ar_ids', event.filteredValue.length)
    },
    addInvoiceToPay(invoice) {
      if (
        this.invoice.invoicesToPay.length == 0 ||
        (this.invoice.invoicesToPay.length > 0 &&
        invoice.cust == this.invoice.invoicesToPay[0].cust)
      ) {
        this.postInvoiceToPay({
          arId: invoice.ar_id,
          balance: parseFloat(invoice.balance),
          dueDate: invoice.due_date,
          cust: invoice.cust,
        });
      } else {
        this.addNotification({
          message: "Invoice belongs to a different customer",
          type: "error",
        });
      }
    },
    handlePaymentSubmitted() {
      this.fetchInvoices(false);
    },
    existInPayArray(invoice) {
      const found = this.invoice.invoicesToPay.find((inv) => {
        return inv.arId == invoice.ar_id;
      });
      if (found) return true;

      return false;
    },
    fetchPDFInvoice(invoiceId) {
      const pdf = this.invoicesView?.find(
        (invoice) => invoice.invoice_num === invoiceId
      );

      if (pdf !== undefined) {
        window.open(pdf.pdf, "_blank");
      } else {
        this.loadingPDFInvoices?.push(invoiceId);

        this.invoiceService
          .getInvoice(invoiceId)
          .then((response) => {
            const bufferArray = base64ToArrayBuffer(response);
            const blobStore = new Blob([bufferArray], {
              type: "application/pdf",
            });
            const data = window.URL.createObjectURL(blobStore);
            this.invoicesView?.push({ invoice_num: invoiceId, pdf: data });

            this.updatePDFLoadingState(invoiceId);

            window.open(data, "_blank");
          })
          .catch(() => {
            this.updatePDFLoadingState(invoiceId);
          });
      }
    },
    invoiceIcon(invoiceId) {
      let downloaded = this.invoicesView;
      let downloading = this.loadingPDFInvoices.includes(invoiceId);
      return {
        "pi pi-download":
          downloaded.find((i) => i.invoice_num === invoiceId) === undefined &&
          !downloading,
        "pi pi-spin pi-spinner": downloading,
        "pi pi-file-pdf":
          downloaded.find((i) => i.invoice_num === invoiceId) !== undefined &&
          !downloading,
      };
    },
    updatePDFLoadingState(invoiceId) {
      const index = this.loadingPDFInvoices.indexOf(invoiceId, 0);
      if (index > -1) this.loadingPDFInvoices.splice(index, 1);
    },
    fetchInvoices(isAddingInvoices) {
      this.loading = true;
      if (this.selectedFilterId !== '' && !this.getArIds(this.index).includes(this.selectedFilterId)) {
            this.loading = false;
            return;
      }
      this.filters.ar_id.value = this.selectedFilterId;
      this.filters.status.value = [...this.selectedFilterStatuses];
      this.filters.type.value = [...this.selectedFilterTypes];

      this.filters.due_date.value = [];
      if (this.selectedDateRange) {
        if (this.selectedDateRange[0] != null) this.dateStart = this.selectedDateRange[0];
        if (this.selectedDateRange[1] != null) this.dateEnd = this.selectedDateRange[1];

        this.dateStart = this.formatDate(this.dateStart);
        this.dateEnd = this.formatDate(this.dateEnd);

        if (this.dateStart) this.filters.due_date.value.push(new Date(this.selectedFilterDate[0]));
        if (this.dateEnd) this.filters.due_date.value.push(new Date(this.selectedFilterDate[1]))
      }
      this.fetchCustomerInvoices({
        index: this.index,
        custId: this.getCustomer(this.index).cust_id,
        dateRange: this.selectedDateRange,
        statuses: this.getStatusesFilter(),
        sortBy: this.sortOrderString + this.sortValue,
        types: this.getTypesFilter(),
        id: this.selectedFilterId,
        isAddingInvoices: isAddingInvoices,
        Client: this.getClient,
      })
        .then(() => {
          this.invoices = this.getInvoices(this.index);
          this.invoices.forEach(invoice => 
          {
            invoice.due_date = new Date(invoice.due_date); 
            invoice.invoice_date = new Date(invoice.invoice_date);
            if(invoice.ach_pending === "Y") {
              invoice.status = "Y"
            }
          } )
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });

      if (!isAddingInvoices) {
        this.page = 1;
        this.first = 0;
      }
    },
    getTypesFilter() {
      let types = [];
      if(this.filters.type.value) {
        types = Object.values(this.filters.type.value);
      }
      return types;
    },
    getStatusesFilter() {
      let statuses = [];
      if(this.filters.status.value) {
        statuses = Object.values(this.filters.status.value);
      }
      return statuses;
    },
    sortData(event) {
      //   if(this.getInvoices.length != most.recent.request.count) {
        this.sortField = event;
      if (this.sortField === "ar_id") {
        this.sortValue = "ar.id";
      } else if (this.sortField === "balance") {
        this.sortValue = "balance";
      } else if (this.sortField === "due_date") {
        this.sortValue = "due.date";
      }

      if (this.sortOrder == -1) {
        this.sortOrderString = "by.dsnd ";
        this.sortOrder = 1 
      } else {
        this.sortOrderString = "by "; 
        this.sortOrder = -1;
      }
      
      // this.fetchInvoices(false);

      // }
      
    },
    handlePage(event) {
      this.updatePage({ pageNumber: event.page, index: this.index });
      /**
       *
       * LOGIC
       *
       * check whether current page is the last page on the paginator
       * if it is - get the next range to be added to be fetched - from the store
       * make the API call and append the result to the array in the store
       * set the next range to be fetched in the store
       */
      if (
        (event.page == event.pageCount ||
          event.page == event.pageCount - 1 ||
          event.page == event.pageCount - 2) &&
        (this.getInvoices(this.index)?.length || 0) ==
          this.getRangeEnd(this.index) - 100
      ) {
        this.fetchInvoices(true);
      }
    },
    displayStatus(object) {
      return object?.ach_pending === "Y"
        ? "PAYMENT PENDING"
        : object.status === "P"
        ? "PAID"
        : "OUTSTANDING";
    },
    clearStatus() {
      this.selectedFilterStatuses = [];
      this.filters.status.value = []
      this.fetchInvoices(false);
    },
    showEmailModal(data) {
      this.currentInvoiceId = data.ar_id;
      this.showEmailInvoiceDialog = true;
    },

    sendEmail(data) {
      this.invoiceService
        .getInvoice(this.currentInvoiceId, data).then((response) => {
          if(response === 'success') {
            this.addNotification({
              message: `Invoice has been emailed successfully`,
              type: "success",
            });
          }
          else {
            this.addNotification({
              message: `Invoice was not sent`,
              type: "error",
            });
          }
        });
    }
  },
};

function base64ToArrayBuffer(data) {
  const bString = window.atob(data);
  const bLength = bString.length;
  const bytes = new Uint8Array(bLength);
  for (let i = 0; i < bLength; i++) {
    const ascii = bString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}
</script>
